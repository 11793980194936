import React, { useEffect, useRef } from "react";
import house from "../../maps_google/dcpHouse.png";
import alipur from "../../maps_google/json_file_of_PS/Alipur.json";
import sawroopNagar from "../../maps_google/json_file_of_PS/SawroopNagar.json";
import bhalswaDairy from "../../maps_google/json_file_of_PS/BhalswaDairy.json";
import bawana from "../../maps_google/json_file_of_PS/Bawana.json";
import nia from "../../maps_google/json_file_of_PS/nia.json";
import samaypurBaldi from "../../maps_google/json_file_of_PS/SamaypurBadli.json";
import shahabadDairy from "../../maps_google/json_file_of_PS/ShahabadDairy.json";
import narela from "../../maps_google/json_file_of_PS/Narela.json";
import Police_Station from "../../../assets/PS.png"
const Alipur = alipur;
const SawroopNagar = sawroopNagar;
const BhalswaDairy = bhalswaDairy;
const Bawana = bawana;
const NIA = nia;
const SamaypurBaldi = samaypurBaldi;
const ShahabadDairy = shahabadDairy;
const Narela = narela;

function MapCompo({ districtData }) {
  const mapRef = useRef(null);
  const markersRef = useRef({ crime: [], staff: [], cctv: [], pcrData: [] });

  const POLICESTATION_ALIPUR = { lat: 28.79887, lng: 77.13859 };
  const POLICESTATION_BAWANA = { lat: 28.79674, lng: 77.04192 };
  const POLICESTATION_BHALWA_DAIRY = { lat: 28.742802, lng: 77.165438 };
  const POLICESTATION_SHAHBAD_DAIRY = { lat: 28.7477049, lng: 77.0788078 };
  const POLICESTATION_SAMAYPUR_BALDI = { lat: 28.725253, lng: 77.141454 };
  const POLICESTATION_SWAROOP_NAGAR = { lat: 28.75847, lng: 77.15613 };
  const POLICESTATION_NIA = { lat: 28.8425349, lng: 77.0882889 };
  const POLICESTATION_NARELA = { lat: 28.85322, lng: 77.08806 };

  function loadGoogleMapsScript() {
    return new Promise((resolve) => {
      const existingScript = document.getElementById("googleMaps");
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAxEYPIcz4RCV2q6VC3JbMmTQahq9yVaG4&libraries=places`;
        script.id = "googleMaps";
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => {
          console.error("Error loading Google Maps script");
          resolve();
        };
        document.body.appendChild(script);
      } else {
        resolve();
      }
    });
  }

  const polygonConfigs = [
    { paths: Alipur, color: "#C04000", name: "Alipur" },
    { paths: SawroopNagar, color: "#008000", name: "Sawroop Nagar" },
    { paths: BhalswaDairy, color: "#C11C84", name: "Bhalswa Dairy" },
    { paths: Bawana, color: "#C11C84", name: "Bawana" },
    { paths: NIA, color: "#0000FF", name: "NIA" },
    { paths: SamaypurBaldi, color: "#ADFF2F", name: "Samaypur Baldi" },
    { paths: ShahabadDairy, color: "#FFFF00", name: "Shahabad Dairy" },
    { paths: Narela, color: "#ADFF2F", name: "Narela" },
  ];

  const initMap = () => {
    if (window.google) {
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: 12,
        center: { lat: 28.79573, lng: 77.096237 },
        mapTypeId: "terrain", // default map type
      });

      markersRef.current.map = map;

      // Initialize district routes
      districtData.forEach((district) => {
        calculateAndDisplayRoute(map, district.coordinates, "#FF0000");
      });

      new window.google.maps.Marker({
        position: { lat: 28.724844, lng: 77.141367 },
        map,
        icon: {
          url: house,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        title: "DCP Outer North Delhi Office",
      });

      // ALIPUR PS place
      new window.google.maps.Marker({
        position: POLICESTATION_ALIPUR,
        map,
        icon: {
          url: Police_Station,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        title: "Alipur PS",
      });

      // Bawana PS
      new window.google.maps.Marker({
        position: POLICESTATION_BAWANA,
        map,
        icon: {
          url: Police_Station,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        title: "Bawana PS",
      });
      // Bhalswa PS
      new window.google.maps.Marker({
        position: POLICESTATION_BHALWA_DAIRY,
        map,
        icon: {
          url: Police_Station,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        title: "Bhalswa Dairy PS",
      });
      // Shahbad Dairy PS
      new window.google.maps.Marker({
        position: POLICESTATION_SHAHBAD_DAIRY,
        map,
        icon: {
          url: Police_Station,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        title: "Shahbad Dairy PS",
      });
      // Swaroop Nagar PS
      new window.google.maps.Marker({
        position: POLICESTATION_SWAROOP_NAGAR,
        map,
        icon: {
          url: Police_Station,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        title: "Swaroop Nagar PS",
      });
      // NIA PS
      new window.google.maps.Marker({
        position: POLICESTATION_NIA,
        map,
        icon: {
          url: Police_Station,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        title: "NIA PS",
      });
      // Narela PS
      new window.google.maps.Marker({
        position: POLICESTATION_NARELA,
        map,
        icon: {
          url: Police_Station,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        title: "Narela PS",
      });

      // Create and display polygons for each district
      polygonConfigs.forEach((config) => {
        const districtPolygon = new window.google.maps.Polygon({
          paths: config.paths,
          strokeColor: config.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: config.color,
          fillOpacity: 0.35,
        });
        districtPolygon.setMap(map);

        const labelMarker = new window.google.maps.Marker({
          position: getPolygonCenter(districtPolygon),
          map: map,
          label: {
            text: config.name,
            color: "#000",
            fontSize: "14px",
            fontWeight: "bold",
          },
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: "transparent",
            fillOpacity: 0,
            strokeWeight: 0,
            scale: 0, // Marker icon is invisible
          },
          optimized: false,
        });
      });

      // Add event listener for zoom changes to toggle map type
      map.addListener("zoom_changed", () => {
        const currentZoom = map.getZoom();
        if (currentZoom >= 15) {
          map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        } else {
          map.setMapTypeId(window.google.maps.MapTypeId.TERRAIN);
        }
      });
    }
  };

  const calculateAndDisplayRoute = (map, coordinates, color) => {
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer({
      map: map,
      polylineOptions: {
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 6,
      },
      suppressMarkers: true,
    });

    const waypoints = coordinates.map((coord) => ({
      location: new google.maps.LatLng(coord.lat, coord.long),
      stopover: true,
    }));
    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: "DRIVING",
        optimizeWaypoints: true,
      },
      (response, status) => {
        if (status === "OK") {
          directionsRenderer.setDirections(response);
        } else {
          console.error("Directions request failed due to " + status);
        }
      }
    );
  };

  useEffect(() => {
    loadGoogleMapsScript().then(initMap);
  }, [districtData]); // Reinitialize the map when data changes

  return <div ref={mapRef} style={{ width: "100vw", height: "100vh" }} />;
}

// Helper function to find the center of a polygon
function getPolygonCenter(poly) {
  const bounds = new window.google.maps.LatLngBounds();
  poly.getPath().forEach(function (point) {
    bounds.extend(point);
  });
  return bounds.getCenter();
}

export default MapCompo;
