import React from 'react'
import data from './District_wise.json'
import OuterNorthDistrictMap from '../../maps_google/outerNorthDistrictMap'
import MapMarkers from './MapMarker'
import Sidebar from '../../maps_google/sidebar/sidebar'
import Header from '../../Header/header'

function DistrictMap1() {
  return (
    <>
      <Header/>
      <Sidebar/>
      <MapMarkers districtData={data}/>
    </>
  )
}

export default DistrictMap1
