import React, { useState, useEffect } from "react";
import "./otp.css";
import axios from "axios";
import { API_URL } from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const OtpVerify = () => {
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    const otptoken = localStorage.getItem("otp-token");
    if (!otptoken) {
      window.location.href = "/";
    } else {
      localStorage.setItem("token", otptoken);
    }
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setResendDisabled(false);
    }
  }, [timer]);

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const phoneNumber = localStorage.getItem("phoneNumber");
      const response = await axios.post(`${API_URL}/verifyOtp`, {
        phoneNumber,
        otp,
      });
      if (response.status === 200) {
        window.location.href = "/homepage";
      } else if (response.status === 400) {
        alert("Invalid OTP. Please try again.");
        // toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      toast.error("Failed to verify OTP. Please try again.");
    }
  };

  const handleResend = () => {
    // Add your resend OTP logic here
    setTimer(300); // Reset timer to 5 minutes
    setResendDisabled(true);
    setMessage("OTP has been resent.");
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      <div className="otp-verify-container">
        <h2>Verify OTP</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={otp}
            onChange={handleChange}
            placeholder="Enter OTP"
            required
          />
          <button type="submit">Verify</button>
        </form>
        {message && <p>{message}</p>}
        <button onClick={handleResend} disabled={resendDisabled}>
          Resend OTP
        </button>
        {resendDisabled && <p>Resend available in: {formatTime(timer)}</p>}
      </div>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </>
  );
};

export default OtpVerify;