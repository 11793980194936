import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element, ...rest }) => {
  const token = localStorage.getItem("otp-token");
  const expiryDate = new Date(localStorage.getItem("expiryDate"));

  if (!token || expiryDate < new Date()) {
    // Redirect to login if not logged in or token expired
    return <Navigate to="/login" />;
  }

  return element; // Simply return the element if the route is protected and valid
};

export default ProtectedRoute;
