import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OuterNorthDistrictMap from "../../maps_google/outerNorthDistrictMap";
import Sidebar from "../../maps_google/sidebar/sidebar";
import Header from "../../Header/header";
import { API_URL } from "../../config/config";
import { API_URL_APP } from "../../config/config";
function Staff_tracking() {
  const location = useLocation();
  const [staffData, setStaffData] = useState([]);

  useEffect(() => {
    // Fetch live location data only when on the "Constable Location" page
    if (location.pathname === "/map/constable") {
      fetchActiveUsers();
    }
  }, [location.pathname]);

  // Fetch active users from the /api/active-users endpoint
  const fetchActiveUsers = async () => {
    try {
      const response = await fetch(`${API_URL}/api/active-users`);

      if (!response.ok) {
        throw new Error("Failed to fetch active users.");
      }

      const activeUsers = await response.json();

      const liveLocations = await Promise.all(
        activeUsers.map(async (user) => {
          try {
            const locationResponse = await fetch(`${API_URL}/users-location`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ phoneNumber: user.mobileNumber }),
            });

            if (!locationResponse.ok) {
              throw new Error(`Failed to fetch location for ${user.name}`);
            }

            const responseText = await locationResponse.text();
            if (!responseText) {
              console.warn(`No location data returned for user ${user.name}`);
              return null;
            }

            const locationData = JSON.parse(responseText);
            return {
              name: user.name,
              phone: user.mobileNumber,
              area: user.areas[0],
              startTime: user.dutyStartTime,
              endTime: user.dutyEndTime,
              lat: locationData.latitude,
              lng: locationData.longitude,
            };
          } catch (error) {
            console.error(
              `Error fetching location for user ${user.name}:`,
              error
            );
            return null;
          }
        })
      );

      setStaffData(liveLocations.filter((location) => location !== null));
    } catch (error) {
      console.error("Error fetching active users or location data:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <OuterNorthDistrictMap staffData={staffData} />
    </>
  );
}

export default Staff_tracking;
