import React, { useState } from 'react';
import './DutyForm.css'; // Make sure the CSS is properly linked

function DutyForm() {
  const [entries, setEntries] = useState({
    sho: ['INSP. ASHOK KUMAR D-1/84'],
    ato: ['INSP.PARDEEP KUMAR D-4853'],
    bravo: ['INSP.MAHENDER KUMAR D-4546'],
    dutyOfficer: ['HC Sunita', 'HC Narender 989/OND', 'ASI Surender'],
    ddWriter: ['W/HC Suman', 'W/HC Kavita 1681/OND', 'W/HC Somulata'],
    sentryDuty: ['CT Ravinder 2485/OND', 'HC Ajeet 2298/OND', 'CT Govind 2585/OND'],
    lockUpSentry: ['8AM TO 8 PM', '8 PM TO 8 AM'],
    readerDakDuty: ['HC YOGENDER 110/OND', 'HC HEMANT 2575/OND'],
    deploymentOfficer: ['ASI RAM KARAN 318/OND'],
    // Continue with additional roles and details...
  });

  const handleChange = (role, index, value) => {
    const newEntries = {...entries};
    newEntries[role][index] = value;
    setEntries(newEntries);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(entries);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="duty-form">
        <h2>ADMINISTRATIVE DUTY (19.11.24) E-CHITTA</h2>
        <table>
          <thead>
            <tr>
              <th>Role</th>
              <th>8AM TO 4PM</th>
              <th>4PM TO 12AM</th>
              <th>12AM TO 8AM</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(entries).map((role) => (
              <tr key={role}>
                <td>{role.toUpperCase().replace(/_/g, ' ')}</td>
                {entries[role].map((entry, index) => (
                  <td key={index}>
                    <input
                      type="text"
                      value={entry}
                      onChange={(e) => handleChange(role, index, e.target.value)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button type="submit">Submit</button>
        <button type="button" onClick={handlePrint}>Print</button>
      </form>
    </div>
  );
}

export default DutyForm;
