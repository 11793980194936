import React from 'react';
import OuterNorthDistrictMap from '../outerNorthDistrictMap';
import Header from '../../Header/header';
import Sidebar from '../sidebar/sidebar'

const CallStats = () => {
  return (
    <>
      <Header />
      <Sidebar /> 
      <OuterNorthDistrictMap />
    </>
  );
};

export default CallStats;
