import React, { useState } from "react";
import "./SearchPCR.css";
import { API_URL } from "../config/config";

const SearchPCR = () => {
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [area, setArea] = useState("");
  const [pcrCalls, setPcrCalls] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${API_URL}/pcr-data/filter/${area}/${date}`
      );
      const data = await response.json();
      setPcrCalls(data);
    } catch (error) {
      console.error("Error fetching PCR data:", error);
    }
  };

  const handleAreaChange = (e) => {
    const value = e.target.value;
    setArea(value);
  };

  const areaNames = [
    "BAWANA",
    "SHAHBAD DAIRY",
    "NARELA",
    "NARELA INDUSTRIAL AREA",
    "ALIPUR",
    "SAMAYPUR BADLI",
    "SWAROOP NAGAR",
    "BHALSWA DAIRY",
  ];

  return (
    <div className="searchPCR-container">
      <h2>Search PCR Calls</h2>
      <form className="searchPCR-container-inputs" onSubmit={handleSubmit}>
        <select
          className="selectoption"
          value={area}
          onChange={handleAreaChange}
          required
        >
          <option value="" disabled>
            Select Area
          </option>
          {areaNames.map((area, index) => (
            <option key={index} value={area}>
              {area}
            </option>
          ))}
        </select>
        <input
          type="date"
          placeholder="Enter date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          max={new Date().toISOString().split("T")[0]}
          required
        />
        <button type="submit">Search</button>
      </form>

      <table>
        <thead>
          <tr>
            <th>Area</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Call Type</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {pcrCalls.map((call, index) => (
            <tr key={index}>
              <td>{call.beat}</td>
              <td>{call.lat}</td>
              <td>{call.long}</td>
              <td>{call.type}</td>
              <td>{call.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SearchPCR;
