import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { API_URL } from "../../config/config";
import OuterNorthDistrictMap from '../../maps_google/outerNorthDistrictMap';
import Header from '../../Header/header';
import Sidebar from '../../maps_google/sidebar/sidebar';

const styles = {
  container: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ddd',
    padding: '10px 20px',
    borderRadius: '8px',
    marginTop: "70px",
    gap: '15px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 10,
  },
  label: {
    backgroundColor: '#87CEEB',
    color: '#333',
    padding: '8px 12px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  select: {
    width: '300px',
    padding: '5px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  entriesBox: {
    marginTop: '10px',
    padding: '10px 15px',
    backgroundColor: '#87CEEB',
    color: '#333',
    fontWeight: 'bold',
    borderRadius: '8px',
    fontSize: '14px',
    textAlign: 'center',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
  },
  mapContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

const areaOptions = [
  { value: "ALL", label: "ALL" },
  { value: "ALIPUR", label: "ALIPUR" },
  { value: "BAWANA", label: "BAWANA" },
  { value: "BHALSWA DAIRY", label: "BHALSWA DAIRY" },
  { value: "NARELA", label: "NARELA" },
  { value: "NARELA INDUSTRIAL AREA", label: "NARELA INDUSTRIAL AREA" },
  { value: "SAMAYPUR BADLI", label: "SAMAYPUR BADLI" },
  { value: "SHAHBAD DAIRY", label: "SHAHBAD DAIRY" },
  { value: "SWAROOP NAGAR", label: "SWAROOP NAGAR" }
];

const crimeOptions = [
  { value: "ALL", label: "ALL" },
  { value: "BURGLARY", label: "BURGLARY" },
  { value: "HOUSE THEFT", label: "HOUSE THEFT" },
  { value: "MV THEFT", label: "MV THEFT" },
  { value: "ROBBERY", label: "ROBBERY" },
  { value: "SNATCHING", label: "SNATCHING" }
];

const monthOptions = [
  { value: "ALL", label: "ALL" },
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" }
];

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    width: '300px',
    padding: '5px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'black',
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};

const CallStats = () => {
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedCrimes, setSelectedCrimes] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [crimeData, setCrimeData] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);

  const handleAreaChange = (selectedOption) => {
    const allSelected = selectedOption.some(option => option.value === "ALL");
    setSelectedAreas(allSelected ? [{ value: "ALL", label: "ALL" }] : selectedOption.filter(option => option.value !== "ALL"));
  };

  const handleCrimeChange = (selectedOption) => {
    const allSelected = selectedOption.some(option => option.value === "ALL");
    setSelectedCrimes(allSelected ? [{ value: "ALL", label: "ALL" }] : selectedOption.filter(option => option.value !== "ALL"));
  };

  const handleMonthChange = (selectedOption) => {
    const allSelected = selectedOption.some(option => option.value === "ALL");
    setSelectedMonths(allSelected ? [{ value: "ALL", label: "ALL" }] : selectedOption.filter(option => option.value !== "ALL"));
  };

  useEffect(() => {
    const fetchCrimeData = async () => {
      try {
        const areaQuery = selectedAreas.some(area => area.value === "ALL") ? areaOptions.map(option => option.value).filter(v => v !== "ALL") : selectedAreas.map(area => area.value);
        const crimeQuery = selectedCrimes.some(crime => crime.value === "ALL") ? crimeOptions.map(option => option.value).filter(v => v !== "ALL") : selectedCrimes.map(crime => crime.value);
        const monthQuery = selectedMonths.some(month => month.value === "ALL") ? monthOptions.map(option => option.value).filter(v => v !== "ALL") : selectedMonths.map(month => month.value);

        let combinedCrimeData = [];
        let combinedTotalEntries = 0;

        for (const area of areaQuery) {
          for (const crime of crimeQuery) {
            for (const month of monthQuery) {
              const response = await fetch(
                `${API_URL}/crime-coordinates/${area}/${crime}?month=${month}`
              );

              if (response.ok) {
                const data = await response.json();
                combinedCrimeData = [...combinedCrimeData, ...data.crimeData];
                combinedTotalEntries += data.totalEntries || 0;
              } else {
                console.error("Failed to fetch data for:", area, crime, month);
              }
            }
          }
        }

        setCrimeData(combinedCrimeData);
        setTotalEntries(combinedTotalEntries);
      } catch (error) {
        console.error("Error fetching crime data:", error);
      }
    };

    if (selectedAreas.length && selectedCrimes.length && selectedMonths.length) {
      fetchCrimeData();
    }
  }, [selectedAreas, selectedCrimes, selectedMonths]);

  return (
    <>
      <Header />
      <Sidebar />

      <div style={styles.container}>
        <div style={styles.label}>PCR CALLS</div>

        <div style={styles.selectContainer}>
          {/* Multi-select for Areas */}
          <Select
            options={areaOptions}
            isMulti
            value={selectedAreas}
            onChange={handleAreaChange}
            placeholder="Select Area(s)"
            styles={customSelectStyles}
          />

          {/* Multi-select for Months */}
          <Select
            options={monthOptions}
            isMulti
            value={selectedMonths}
            onChange={handleMonthChange}
            placeholder="Select Month(s)"
            styles={customSelectStyles}
          />

          {/* Multi-select for Crimes */}
          <Select
            options={crimeOptions}
            isMulti
            value={selectedCrimes}
            onChange={handleCrimeChange}
            placeholder="Select Crime(s)"
            styles={customSelectStyles}
          />
        </div>

        <div style={styles.entriesBox}>
          Total Entries: <strong>{totalEntries}</strong>
        </div>
      </div>

      <div style={styles.mapContainer}>
        <OuterNorthDistrictMap crimeData={crimeData} />
      </div>
    </>
  );
};

export default CallStats;
