import React from 'react'
import data from './District_wise.json'
import OuterNorthDistrictMap from '../../maps_google/outerNorthDistrictMap'
import MapCompo from './mapcompo'
import Sidebar from '../../maps_google/sidebar/sidebar'
import Header from '../../Header/header'

function DistrictMap() {
  return (
    <>
      <Header/>
      <Sidebar/>
      <MapCompo districtData={data}/>
    </>
  )
}

export default DistrictMap
