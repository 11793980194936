import React from "react";
import Pcr_crime from "./PCR_calls/pcr_crime";
import "./map.css";

function App() {
  return (
    <>
      <Pcr_crime />
    </>
  );
}

export default App;
