import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import OuterNorthDistrictMap from '../../maps_google/outerNorthDistrictMap';
import Header from '../../Header/header';
import Sidebar from '../../maps_google/sidebar/sidebar';
import { API_URL, API_URL_APP } from '../../config/config';
import ANPR from '../CCTV_tracking/ANPR.json';
import GOVT from '../CCTV_tracking/GOVT.json';
import ECIL from '../CCTV_tracking/ECIL.json';

const All = () => {
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedCrimes, setSelectedCrimes] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedCameraTypes, setSelectedCameraTypes] = useState([]);
  const [crimeData, setCrimeData] = useState([]);
  const [cctv, setCctvData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [showStaffLocations, setShowStaffLocations] = useState(false); // State to control showing staff data
  const [pcrTotalEntries, setPcrTotalEntries] = useState(0);
  const [cctvTotalEntries, setCctvTotalEntries] = useState(0);

  const sheetId = '1QZkX__3SnwEHk6vZnOzuILHCXkkqyLNJ0ZK0Y4OJL8s';
  const apiKey = 'AIzaSyCu9pysN7NS0U7NdvFkxH1TDonMfn5Ne0U';
  const range = 'Sheet1!A1:G1000';
  const apiUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`;

  const fetchPrivateCameraData = async () => {
    try {
      const response = await axios.get(apiUrl);
      const rows = response.data.values;

      // Map the rows to JSON structure, ignoring the header row
      return rows.slice(1).map(row => ({
        name: row[0],
        phone: row[1],
        lat: parseFloat(row[2]),
        long: parseFloat(row[3]),
        cameraType: row[4],
        notes: row[5],
        policeStation: row[6],
      }));
    } catch (error) {
      console.error('Error fetching data from Google Sheets:', error);
      return [];
    }
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      width: '200px',
      padding: '5px',
      fontSize: '14px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      marginBottom: '10px',
      color: '#000',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '14px',
    }),
  };

  const cameraTypeOptions = [
    { value: "ANPR", label: "ANPR" },
    { value: "ECIL", label: "ECIL" },
    { value: "govt", label: "Government" },
    { value: "private", label: "Private" },
  ];

  const handleCameraTypeChange = async (selectedOptions) => {
    setSelectedCameraTypes(selectedOptions);
    
    let combinedCctvData = [];
    let totalCctvEntries = 0;
  
    for (const option of selectedOptions) {
      switch (option.value) {
        case "ANPR":
          combinedCctvData = [...combinedCctvData, ...ANPR];
          totalCctvEntries += ANPR.length;
          break;
        case "ECIL":
          combinedCctvData = [...combinedCctvData, ...ECIL];
          totalCctvEntries += ECIL.length;
          break;
        case "govt":
          combinedCctvData = [...combinedCctvData, ...GOVT];
          totalCctvEntries += GOVT.length;
          break;
        case "private":
          const privateData = await fetchPrivateCameraData();
          combinedCctvData = [...combinedCctvData, ...privateData];
          totalCctvEntries += privateData.length;
          break;
        default:
          break;
      }
    }
  
    setCctvData(combinedCctvData);
    setCctvTotalEntries(totalCctvEntries);
  };
  

  useEffect(() => {
    const fetchCrimeData = async () => {
      try {
        const areaQuery = selectedAreas.map(area => area.value);
        const crimeQuery = selectedCrimes.map(crime => crime.value);
        const monthQuery = selectedMonths.map(month => month.value);

        let allCrimeData = [];
        let totalEntries = 0;

        for (const area of areaQuery) {
          for (const crime of crimeQuery) {
            for (const month of monthQuery) {
              const requestUrl = `${API_URL}/crime-coordinates/${area}/${crime}?month=${month}`;
              const response = await fetch(requestUrl);

              if (response.ok) {
                const data = await response.json();
                allCrimeData = [...allCrimeData, ...data.crimeData];
                totalEntries += data.totalEntries || 0;
              } else {
                console.error(`Failed to fetch data for URL: ${requestUrl}`);
              }
            }
          }
        }

        setCrimeData(allCrimeData);
        setPcrTotalEntries(totalEntries);

      } catch (error) {
        console.error("Error fetching crime data:", error);
      }
    };

    fetchCrimeData();
  }, [selectedAreas, selectedCrimes, selectedMonths]);

  const fetchActiveUsers = async () => {
    try {
      const response = await fetch(`${API_URL}/api/active-users`);
  
      if (!response.ok) {
        throw new Error("Failed to fetch active users.");
      }
  
      const activeUsers = await response.json();
  
      const liveLocations = await Promise.all(
        activeUsers.map(async (user) => {
          try {
            const locationResponse = await fetch(`${API_URL_APP}/users-location`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ phoneNumber: user.mobileNumber }),
            });
  
            if (!locationResponse.ok) {
              throw new Error(`Failed to fetch location for ${user.name}`);
            }
  
            const responseText = await locationResponse.text();
            if (!responseText) {
              console.warn(`No location data returned for user ${user.name}`);
              return null;
            }
  
            const locationData = JSON.parse(responseText);
            return {
              name: user.name,
              phone: user.mobileNumber,
              area: user.areas[0],
              startTime: user.dutyStartTime,
              endTime: user.dutyEndTime,
              lat: locationData.latitude,
              lng: locationData.longitude,
            };
          } catch (error) {
            console.error(`Error fetching location for user ${user.name}:`, error);
            return null;
          }
        })
      );
  
      setStaffData(liveLocations.filter(location => location !== null));
      setShowStaffLocations(true); // Set to true to show locations on the map
    } catch (error) {
      console.error("Error fetching active users or location data:", error);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#ddd',
        padding: '10px 20px',
        borderRadius: '8px',
        gap: '15px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 10,
      }}>
        <div style={{
          backgroundColor: '#87CEEB',
          color: '#333',
          padding: '8px 12px',
          borderRadius: '8px',
          fontSize: '16px',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}>All Tracking</div>

        {/* PCR Filters */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Select
            options={[
              { value: "ALIPUR", label: "ALIPUR" },
              { value: "BAWANA", label: "BAWANA" },
              { value: "BHALSWA DIARY", label: "BHALSWA DIARY" },
              { value: "NARELA", label: "NARELA" },
              { value: "NARELA INDUSTRIAL AREA", label: "NARELA INDUSTRIAL AREA" },
              { value: "SAMAYPUR BADLI", label: "SAMAYPUR BADLI" },
              { value: "SHAHBAD DAIRY", label: "SHAHBAD DAIRY" },
              { value: "SWAROOP NAGAR", label: "SWAROOP NAGAR" }
            ]}
            isMulti
            value={selectedAreas}
            onChange={setSelectedAreas}
            placeholder="Select Area(s)"
            styles={customSelectStyles}
          />

          <Select
            options={[
              { value: "January", label: "January" },
              { value: "February", label: "February" },
              { value: "March", label: "March" },
              { value: "April", label: "April" },
              { value: "May", label: "May" },
              { value: "June", label: "June" },
              { value: "July", label: "July" },
              { value: "August", label: "August" },
              { value: "September", label: "September" },
              { value: "October", label: "October" },
              { value: "November", label: "November" },
              { value: "December", label: "December" }
            ]}
            isMulti
            value={selectedMonths}
            onChange={setSelectedMonths}
            placeholder="Select Month(s)"
            styles={customSelectStyles}
          />

          <Select
            options={[
              { value: "BURGLARY", label: "BURGLARY" },
              { value: "HOUSE THEFT", label: "HOUSE THEFT" },
              { value: "MV THEFT", label: "MV THEFT" },
              { value: "ROBBERY", label: "ROBBERY" },
              { value: "SNATCHING", label: "SNATCHING" }
            ]}
            isMulti
            value={selectedCrimes}
            onChange={setSelectedCrimes}
            placeholder="Select Crime(s)"
            styles={customSelectStyles}
          />
        </div>

        {/* CCTV Filter */}
        <div style={{
          marginTop: '20px',
          backgroundColor: '#87CEEB',
          color: '#333',
          padding: '8px 12px',
          borderRadius: '8px',
          fontSize: '16px',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}>CCTV Tracking</div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Select
            options={cameraTypeOptions}
            isMulti
            value={selectedCameraTypes}
            onChange={handleCameraTypeChange}
            placeholder="Select Camera Type(s)"
            styles={customSelectStyles}
          />
        </div>

        {/* Show Staff Locations Button */}
        <button 
          onClick={fetchActiveUsers} 
          style={{
            padding: '10px 15px',
            backgroundColor: '#4CAF50',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontWeight: 'bold'
          }}
        >
          Show Staff Locations
        </button>

        {/* Total Entries */}
        <div style={{
          marginTop: '10px',
          padding: '10px 15px',
          backgroundColor: '#87CEEB',
          color: '#333',
          fontWeight: 'bold',
          borderRadius: '8px',
          fontSize: '14px',
          textAlign: 'center',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        }}>
          PCR Entries: <strong>{pcrTotalEntries}</strong><br />
          CCTV Entries: <strong>{cctvTotalEntries}</strong><br />
          Staff Entries: <strong>{staffData.length}</strong>
        </div>
      </div>

      {/* Map Display */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}>
        <OuterNorthDistrictMap 
          crimeData={crimeData} 
          cctv={cctv} 
          staffData={showStaffLocations ? staffData : []} // Conditionally display staff data
        />
      </div>
    </>
  );
};

export default All;
