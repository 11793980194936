import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";

import "./App.css";
import ProtectedRoute from "./components/utility/protectedRoute.js";

import StaffManagement from "./components/addremovestaff/StaffManagement.js";
import StaffMembers from "./components/staff/StaffMembers.js";
import Login from "./components/Login/login.js";
import DutyTask from "./components/dutychart/DutyTask.js";
import ProfileScreen from "./components/Profile/P.js";
import Dashboard from "./components/dashboard/Dashboard.js";
import Crime from "./components/crimes/crime.js";
import PCR from "./components/PCR/pcr.js";

import NotFound from "./components/NotFound/notFound.js";
import Landing from "./components/Landing/Landing.jsx";
import PrivacyPolicy from "./Misc/Privacy Policy/privacyPolice.jsx";
import TermsAndConditions from "./Misc/T&C/TermsAndConditions.jsx";
import Map from "./components/maps_google/map_main.js";
import OtpVerification from "./components/OtpVerification/OtpVerification.js";
import Pcr_tracking from "./components/Tracking_feature/PCR_teacking/pcr_tracking.js";
import Cctv_tracking from "./components/Tracking_feature/CCTV_tracking/cctv_tracking.js";
import Staff_tracking from "./components/Tracking_feature/staff_tracking/staff_tracking.js";
import All from "./components/Tracking_feature/All_tracking/all_tracking.js";
import Vehicle_tracking from "./components/Tracking_feature/Vehicle_tracking/Vehicle_tracking.js";
import Crime_tracking from "./components/Tracking_feature/crime_tracking_FIR/crime_tracking.js";
import BestRoute from "./components/Tracking_feature/BestRoute/DistrictMap.js";
import DistrictMap1 from "./components/Tracking_feature/BestRoute/DistrictMap1.js";
import DutyTask_new from "./components/dutychart_new/DutyTask_new.js";

function App() {
  return (
    <Router>
      <AppWithRouting />
    </Router>
  );
}

function AppWithRouting() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("otp-token");
    const expiryDate = new Date(localStorage.getItem("expiryDate"));
    const currentPath = window.location.pathname; // Get the current path

    // If the user is logged in and the token is valid
    if (token && expiryDate > new Date()) {
      // Prevent redirection to homepage if the user is already on protected routes
      if (currentPath === "/login" || currentPath === "/") {
        navigate("/homepage");
      }
    } else {
      // If the user is not logged in and the current path is not login/verify-otp
      if (currentPath !== "/login" && currentPath !== "/verify-otp") {
        navigate("/login");
      }
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify-otp" element={<OtpVerification />} />
      <Route path="/StaffManagement" element={<StaffManagement />} />

      {/* Protected Routes */}
      <Route path="/StaffMembers" element={<ProtectedRoute element={<StaffMembers />} />} />
      <Route path="/DutyTask" element={<ProtectedRoute element={<DutyTask />} />} />
      <Route path="/DutyChart" element={<ProtectedRoute element={<DutyTask_new />} />} />
      
      {/* Other Routes */}
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/Profile" element={<ProfileScreen />} />
      <Route path="/homepage" element={<ProtectedRoute element={<Dashboard />} />} />
      <Route path="/crimeEntry" element={<Crime />} />
      <Route path="/PCR" element={<PCR />} />
      <Route path="/Map" element={<Map />} />
      <Route path="/map/PCR" element={<Pcr_tracking />} />
      <Route path="/map/cctv" element={<Cctv_tracking />} />
      <Route path="/map/constable" element={<Staff_tracking />} />
      <Route path="/map/all" element={<All />} />
      <Route path="/map/gps" element={<Vehicle_tracking />} />
      <Route path="/map/crime" element={<Crime_tracking />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/map/patrol" element={<BestRoute />} />
      <Route path="/map/mark" element={<DistrictMap1 />} />
    </Routes>
  );
}

export default App;
